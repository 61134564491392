import axios from "axios";
import AuthService from "../Auth/AuthService"

export default class PermissoesService extends AuthService {

    listar() {
        return axios({
            method: 'get',
            url: this.api_url + 'permissoes',
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        }).then(res => res.data);
    }
}
