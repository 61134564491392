 <template>
  <Toast />
  <Panel header="Gerenciamento de Usuários" style="height: 100%">
    <Toolbar class="p-mb-4">
      <template v-slot:left>
        <Button
          label="Novo"
          icon="pi pi-plus"
          class="p-button-success p-mr-2 p-mb-2"
          @click="abrirCadastrar"
        />
      </template>
      <template v-slot:right>
        <Button
          label="Exportar"
          icon="pi pi-upload"
          class="p-button-help p-mb-2"
          @click="exportCSV($event)"
        />
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="usuarios"
      class="p-datatable-sm p-datatable-striped"
      v-model:selection="selecionaUsuario"
      dataKey="id"
      :paginator="true"
      :rows="10"
      :filters="filters"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 20, 50]"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
    >
      <template #header>
        <div class="table-header">
          <h5 class="p-m-0">Usuários</h5>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global']" placeholder="Pesquisar..." />
          </span>
        </div>
      </template>
      <template #empty>
        <div class="p-text-center">Nenhum resultado encontrado...</div>
      </template>
      <Column header="Usuario" field="nome" :sortable="true">
        <template #body="slotProps">
          <span class="p-column-title">Usuario:</span>
          {{ slotProps.data.nome }}
        </template>
      </Column>
      <Column header="Email" field="email" :sortable="true">
        <template #body="slotProps">
          <span class="p-column-title">Email:</span>
          {{ slotProps.data.email }}
        </template>
      </Column>
      <Column header="Status" field="ativo" :sortable="true">
        <template #body="slotProps">
          <span class="p-column-title">Status:</span>
          <span
            v-if="slotProps.data.ativo === true"
            :class="'product-badge status-' + slotProps.data.ativo"
            @click="alterarStatus(slotProps.data)"
            v-tooltip.top="'Clique para INATIVAR'"
            style="cursor: pointer"
            >Ativo</span
          >
          <span
            v-if="slotProps.data.ativo === false"
            :class="'product-badge status-' + slotProps.data.ativo"
            @click="alterarStatus(slotProps.data)"
            v-tooltip.top="'CLIQUE ATIVAR'"
            style="cursor: pointer"
            >Inativo</span
          >
          <!--{{ slotProps.data.ativo }}-->
        </template>
      </Column>
      <Column header="Permissões" field="permissoes">
        <template #body="slotProps">
          <span class="p-column-title">Permissões:</span>
          <span
            v-if="slotProps.data.tipo === 'Administrador'"
            :class="'product-badge status-' + slotProps.data.tipo"
            >Administrador</span
          >
          <span
            v-if="slotProps.data.tipo === 'Operador'"
            :class="'product-badge status-' + slotProps.data.tipo"
            >Operador</span
          >
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="abrirEditar(slotProps.data)"
            v-tooltip.top="'CLIQUE PARA EDITAR'"
          />
          <Button
            icon="pi pi-lock"
            class="p-button-rounded p-button-info p-mr-2"
            @click="abrirResetarSenha(slotProps.data)"
            v-tooltip.top="'CLIQUE PARA RESETAR SENHA'"
          />
          <Button
            icon="pi pi-lock-open"
            class="p-button-rounded p-button-help p-mr-2"
            @click="abrirAlterarSenha(slotProps.data)"
            v-tooltip.top="'CLIQUE PARA ALTERAR SENHA'"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="abrirDeletar(slotProps.data)"
            v-tooltip.top="'CLIQUE PARA EXCLUIR'"
          />
        </template>
      </Column>
    </DataTable>
  </Panel>

  <!--MODAL CADASTRAR-->
  <Dialog
    v-model:visible="usuarioDialog"
    :style="{ width: '800px' }"
    header="Cadastro de Usuário"
    :modal="true"
    class="p-fluid"
  >
    <br />
    <!--  CADASTRO DE USUARIO -->
    <div class="p-shadow-4" style="padding: 30px">
      <div class="p-text-bold">
        <h5>Usuários e Permissões</h5>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
          <label for="nome">Nome</label>
          <InputText
            id="nome"
            :class="{ 'p-invalid': submitted && !usuario.nome }"
            v-model="usuario.nome"
          />
          <small class="p-invalid" v-if="submitted && !usuario.nome"
            >Nome é Obrigatorio.</small
          >
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="email">Email</label>
          <InputText
            id="email"
            type="email"
            :class="{ 'p-invalid': submitted && !usuario.email }"
            v-model="usuario.email"
          />
          <small class="p-invalid" v-if="submitted && !usuario.email"
            >Email é Obrigatorio.</small
          >
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
          <label for="senha">Senha</label>
          <InputText
            id="senha"
            type="password"
            :class="{ 'p-invalid': submitted && !usuario.senha }"
            v-model="usuario.senha"
          />
          <small class="p-invalid" v-if="submitted && !usuario.senha"
            >Senha é Obrigatorio.</small
          >
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="confSenha">Confirmar Senha</label>
          <InputText
            id="confSenha"
            type="password"
            :class="{ 'p-invalid': submitted && !confSenha }"
            v-model="confSenha"
          />
          <small class="p-invalid" v-if="submitted && !confSenha"
            >Confirmar senha é Obrigatorio.</small
          >
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
          <label for="tipo">Tipo</label>
          <Dropdown
            id="tipo"
            :options="tipoUsuario"
            optionLabel="tipo"
            optionValue="tipo"
            placeholder="Selecione um Tipo "
            :class="{ 'p-invalid': submitted && !usuario.tipo }"
            v-model="usuario.tipo"
          />
          <small class="p-invalid" v-if="submitted && !usuario.tipo"
            >Tipo é Obrigatorio.</small
          >
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <!--  CADASTRO DE PERMISSOES -->
        <div class="p-col-12 p-shadow-4" style="padding: 30px">
          <PickList
            v-model="usuario.permissoes"
            listStyle="height:342px"
            dataKey="id"
          >
            <template #sourceHeader>Selecione</template>
            <template #targetHeader>Selecionado </template>
            <template #item="slotProps">
              <div class="product-list-detail">
                <h5 class="p-mb-2">
                  <i class="pi pi-unlock product-category-icon"> </i>
                  Permissão - {{ slotProps.item.id }}
                </h5>
                <span class="product-category">
                  {{ slotProps.item.descricao }}</span
                >
              </div>
            </template>
          </PickList>
        </div>
        <!--  CADASTRO DE USUARIO -->
      </div>
    </div>
    <br />
    <template #footer>
      <Button
        label="Salvar"
        class="p-button-raised"
        icon="pi pi-check"
        @click="cadastrarusuario"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="usuarioDialog = false"
      />
    </template>
  </Dialog>
  <!--FIM MODAL CADASTRAR-->

  <!--MODAL ALTERAR-->
  <Dialog
    v-model:visible="usuarioAlterarDialog"
    :style="{ width: '800px' }"
    header="Edição de usuario"
    :modal="true"
    class="p-fluid"
  >
    <br />
    <!--  CADASTRO DE USUARIO -->
    <div class="p-shadow-4" style="padding: 30px">
      <div class="p-text-bold">
        <h5>Usuários e Permissões</h5>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
          <label for="nome">Nome</label>
          <InputText
            id="nome"
            :class="{ 'p-invalid': submitted && !usuario.nome }"
            v-model="usuario.nome"
          />
          <small class="p-invalid" v-if="submitted && !usuario.nome"
            >Nome é Obrigatorio.</small
          >
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="email">Email</label>
          <InputText
            id="email"
            type="email"
            :class="{ 'p-invalid': submitted && !usuario.email }"
            v-model="usuario.email"
          />
          <small class="p-invalid" v-if="submitted && !usuario.email"
            >Email é Obrigatorio.</small
          >
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
          <label for="tipo">Tipo</label>
          <Dropdown
            id="tipo"
            :options="tipoUsuario"
            optionLabel="tipo"
            optionValue="tipo"
            :class="{ 'p-invalid': submitted && !usuario.tipo }"
            v-model="usuario.tipo"
          />
          <small class="p-invalid" v-if="submitted && !usuario.tipo"
            >Tipo é Obrigatorio.</small
          >
        </div>

        <div class="p-field p-col-12 p-md-6">
          <label for="status">Status</label><br />
          <InputSwitch id="status" v-model="usuario.ativo" />
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <!--  CADASTRO DE PERMISSOES DO USUARIO -->
        <div class="p-col-12 p-shadow-4" style="padding: 30px">
          <PickList
            v-model="usuario.permissoes"
            listStyle="height:342px"
            dataKey="id"
          >
            <template #sourceHeader>Selecione:</template>
            <template #targetHeader>Selecionado:</template>
            <template #item="slotProps">
              <div class="product-list-detail">
                <h5 class="p-mb-2">
                  <i class="pi pi-unlock product-category-icon"> </i>
                  Permissão - {{ slotProps.item.id }}
                </h5>
                <span class="product-category">
                  {{ slotProps.item.descricao }}</span
                >
              </div>
            </template>
          </PickList>
        </div>
        <!--  CADASTRO DE PERMISSOES DO USUARIO -->
      </div>
    </div>
    <br />
    <template #footer>
      <Button
        label="Salvar"
        class="p-button-raised"
        icon="pi pi-check"
        @click="alterarusuario"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="usuarioAlterarDialog = false"
      />
    </template>
  </Dialog>
  <!--FIM MODAL MODAL ALTERAR-->

  <!--MODAL DELETAR-->
  <Dialog
    v-model:visible="usuarioDeletaDialog"
    :style="{ width: '450px' }"
    header="Deseja Excluir este Usuário?"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span v-if="usuario"
        >Tem certeza de que deseja excluir do sistema o usuario
        <b>{{ usuario.nome }} ?</b>
      </span>
    </div>
    <template #footer>
      <Button
        label="Sim"
        icon="pi pi-check"
        class="p-button-raised"
        @click="excluirusuario"
      />
      <Button
        label="Não"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="usuarioDeletaDialog = false"
      />
    </template>
  </Dialog>
  <!--FIM MODAL DELETAR-->

  <!--MODAL DELETAR SELECIONADOS-->
  <Dialog
    v-model:visible="deletaSelecionadoDialog"
    :style="{ width: '450px' }"
    header="Mensagem de Confirmação!"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span v-if="pessoa"
        >Tem certeza de que deseja excluir os registros selecionados?
      </span>
    </div>
    <template #footer>
      <Button
        label="Sim"
        icon="pi pi-check"
        class="p-button-raised"
        @click="deletarSelecioandos()"
      />
      <Button
        label="Não"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="deletaSelecionadoDialog = false"
      />
    </template>
  </Dialog>
  <!--FIM MODAL DELETAR SELECIONADOS-->

  <!--MODAL RESETAR SENHA-->
  <Dialog
    v-model:visible="usuarioResetaDialog"
    :style="{ width: '450px' }"
    header="Deseja resetar a senha?"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span v-if="usuario"
        >Tem certeza de que deseja resetar a senha do usuario
        <b>{{ usuario.nome }} ?</b>
      </span>
    </div>
    <template #footer>
      <Button
        label="Sim"
        icon="pi pi-check"
        class="p-button-raised"
        @click="resetarSenha"
      />
      <Button
        label="Não"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="usuarioDeletaDialog = false"
      />
    </template>
  </Dialog>
  <!--FIM MODAL RESETAR SENHA-->

  <!--MODAL ALTERAR SENHA-->
  <Dialog
    v-model:visible="usuarioAlterarSenhaDialog"
    :style="{ width: '400px' }"
    header="Alterar Senha"
    :modal="true"
    class="p-fluid"
  >
    <br />
    <!--  CADASTRO DE USUARIO -->
    <div class="p-shadow-4" style="padding: 30px">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
          <label for="senhaAtual">Senha Atual</label>
          <Password
            v-model="altSenha.senhaAntiga"
            :feedback="false"
            toggleMask="true"
          ></Password>
        </div>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
          <label for="nome">Nova Senha</label>
          <Password
            v-model="altSenha.novaSenha1"
            :feedback="false"
            toggleMask
          ></Password>
        </div>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
          <label for="nome">Confirmar Nova Senha</label>
          <Password
            v-model="altSenha.novaSenha2"
            :feedback="false"
            toggleMask="true"
          ></Password>
        </div>
      </div>
    </div>
    <br />
    <template #footer>
      <Button
        label="Salvar"
        class="p-button-raised"
        icon="pi pi-check"
        @click="alterarSenha"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="usuarioAlterarDialog = false"
      />
    </template>
  </Dialog>
  <!--FIM MODAL MODAL ALTERAR SENHA-->
</template>

<script>
import UsersService from "@/service/Users/UsersService";
import PermissoesService from "@/service/Permissoes/PermissoesService";

import AuthService from "@/service/Auth/AuthService";

export default {
  data() {
    return {
      checked1: false,
      usuario: {
        nome: null,
        email: null,
        senha: null,
        ativo: true,
        permissoes: null,
      },
      altSenha: {
        senhaAntiga: null,
        novaSenha1: null,
        novaSenha2: null,
      },
      tipoUsuario: [{ tipo: "Administrador" }, { tipo: "Operador" }],
      dados: null,
      confSenha: null,
      usuarios: null,
      usuarioDialog: false,
      usuarioAlterarDialog: false,
      usuarioDeletaDialog: false,
      usuarioResetaDialog: false,
      usuarioAlterarSenhaDialog: false,
      submitted: null,
      filters: {},
      selecionaUsuario: null,
      permissao: [],
      faltantes: [],
    };
  },
  usersService: null,
  authService: null,
  permissoesService: null,
  created() {
    this.usersService = new UsersService();
    this.permissoesService = new PermissoesService();
    this.authService = new AuthService();
  },
  mounted() {
    this.authService.checarToken(this.listarUsuarios());
    this.authService.checarToken(this.listarPermissoes());
  },
  methods: {
    abrirCadastrar() {
      this.authService.checarToken(this.listarPermissoes());
      this.submitted = false;
      this.usuarioDialog = true;
      this.confSenha = null;
      this.usuario = {
        nome: null,
        email: null,
        senha: null,
        ativo: true,
        permissoes: this.permissao,
      };
    },
    abrirEditar(usuario) {
      this.usuario = usuario;
      //VERIFICANDO A DIFRENÇA DE PERMISSÕES
      this.faltantes = this.permissao[0].filter(
        ({ id: id1 }) =>
          !this.usuario.permissoes.some(({ id: id2 }) => id2 === id1)
      );
      this.usuario = {
        id: usuario.id,
        nome: usuario.nome,
        email: usuario.email,
        ativo: usuario.ativo,
        tipo: usuario.tipo,
        permissoes: [this.faltantes, this.usuario.permissoes],
      };
      this.usuarioAlterarDialog = true;
    },
    abrirDeletar(usuario) {
      this.usuario = usuario;
      this.usuarioDeletaDialog = true;
    },
    abrirResetarSenha(usuario) {
      this.usuario = usuario;
      this.usuarioResetaDialog = true;
    },
    abrirAlterarSenha(usuario) {
      this.usuario = usuario;
      this.usuarioAlterarSenhaDialog = true;
    },
    listarUsuarios() {
      this.usersService.listar().then((data) => {
        this.usuarios = data.content;
      });
    },
    listarPermissoes() {
      this.permissoesService.listar().then((data) => {
        this.permissao = [data, []];
      });
    },
    /**OK */
    cadastrarusuario() {
      this.submitted = true;
      this.dados = {
        nome: this.usuario.nome,
        email: this.usuario.email,
        senha: this.usuario.senha,
        ativo: this.usuario.ativo,
        tipo: this.usuario.tipo,
        permissoes: this.usuario.permissoes[1],
      };
      this.authService.checarToken(
        this.usersService.criar(this.dados).then((data) => {
          //  console.log(data);
          if (data.status === 201) {
            this.usuarioDialog = false;
            this.usuario = {
              nome: null,
              email: null,
              senha: null,
              ativo: true,
              permissoes: this.permissao,
            };
            this.listarUsuarios();
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Novo usuário cadastrado com sucesso.",
            });
          }
        })
      );
    },
    alterarusuario() {
      this.submitted = true;
      this.dados = {
        nome: this.usuario.nome,
        email: this.usuario.email,
        senha: "12345678910",
        ativo: this.usuario.ativo,
        tipo: this.usuario.tipo,
        permissoes: this.usuario.permissoes[1],
      };
      this.authService.checarToken(
        this.usersService
          .atualizar(this.usuario.id, this.dados)
          .then((data) => {
            console.log(data);
            if (data.status === 200) {
              this.usuarioAlterarDialog = false;
              this.usuario = {
                nome: null,
                email: null,
                senha: null,
                ativo: true,
                permissoes: this.permissao,
              };
              this.listarUsuarios();
              this.$toast.add({
                severity: "success",
                summary: "Alerta!",
                detail:
                  "Usuario " + this.dados.nome + " foi alterado com sucesso.",
              });
            }
          })
      );

      /*
      this.usersService
        .atualizar(this.usuario.id, this.dados, this.token())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error.error);
        });
      */

      /*
      this.usersService
        .atualizar(this.usuario.id, this.dados, this.token())
        .then((data) => {
          //console.log(data.data);
          if (data.status === 200) {
            this.usuarioAlterarDialog = false;
            this.listarusuarios();
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Usuario alterado com sucesso.",
            });
          }
        })
        .catch((error) => {
          console.log(error.error);
        });
      */
      /*
      if (this.authService.verificarTokenExpirado() === true) {
        this.authService.obterNovoAccessToken().then((response) => {
          if (response.status === 200) {
            this.authService.armazenaToken(response.data);
            this.usersService
              .atualizar(this.usuario.id, this.usuario, this.token())
              .then((data) => {
                //console.log(data.data);
                if (data.status === 200) {
                  this.usuarioAlterarDialog = false;
                  this.listarusuarios();
                  this.$toast.add({
                    severity: "success",
                    summary: "Alerta!",
                    detail: "usuario alterado com sucesso.",
                  });
                }
              })
              .catch((error) => {
                console.log(error.error);
              });
          }
        });
      } else {
        this.usuariosAdministratorService
          .atualizar(this.usuario.id, this.usuario, this.token())
          .then((data) => {
            //console.log(data.data);
            if (data.status === 200) {
              this.usuarioAlterarDialog = false;
              this.listarusuarios();
              this.$toast.add({
                severity: "success",
                summary: "Alerta!",
                detail: "usuario alterado com sucesso.",
              });
            }
          })
          .catch((error) => {
            console.log(error.error);
          });
      }*/
    },
    /**OK */
    excluirusuario() {
      this.authService.checarToken(
        this.usersService.remover(this.usuario.id).then((data) => {
          if (data.status === 204) {
            this.usuarioDeletaDialog = false;
            this.listarUsuarios();
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Usuario excluido com sucesso.",
            });
          }
        })
      );
    },
    /**OK */
    excluirusuarios() {
      console.log("ExcluidoS com sucesso");
    },
    /**OK */
    resetarSenha() {
      this.authService.checarToken(
        this.usersService.resetarSenha(this.usuario.id).then((data) => {
          if (data.status === 204) {
            this.usuarioResetaDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Senha restada com sucesso.",
            });
          }
        })
      );
    },
    /**OK */
    alterarSenha() {
      this.authService.checarToken(
        this.usersService
          .alterarSenha(this.usuario.id, this.altSenha)
          .then((data) => {
            if (data.status === 204) {
              this.usuarioAlterarSenhaDialog = false;
              this.altSenha = {
                senhaAntiga: null,
                novaSenha1: null,
                novaSenha2: null,
              };
              this.listarUsuarios();
              this.$toast.add({
                severity: "success",
                summary: "Alerta!",
                detail: "Senha alterada com sucesso.",
              });
            }
          })
          .catch((error) => {
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail:
                "Mensagem: " + error.response.data[0].mensagemUsuario + "!",
            });
            console.log();
          })
      );
    },
    /**OK */
    alterarStatus(usuario) {
      this.usuario = usuario;
      if (this.usuario.ativo === true) {
        this.authService.checarToken(
          this.usersService
            .alterarStatus(this.usuario.id, "false")
            .then(() => {
              //console.log(data)
              this.listarUsuarios();
            })
            .catch((error) => {
              console.error(error);
            })
        );
      } else {
        this.authService.checarToken(
          this.usersService
            .alterarStatus(this.usuario.id, "true")
            .then(() => {
              this.listarUsuarios();
            })
            .catch((error) => {
              console.error(error);
            })
        );
      }
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-m {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-f {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-Administrador {
    background: #feedaf;
    color: #8a5340;
  }

  &.status-Operador {
    background: #c0c0c0;
    color: #000000;
  }
}

.p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }

        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: right;
        }

        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}

product-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;

  .product-list-detail {
    flex: 1 1 0;
  }

  .product-list-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .product-category-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
  }

  .product-category {
    vertical-align: middle;
    line-height: 1;
  }
}

.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-true {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-false {
    background: #ffcdd2;
    color: #c63737;
  }
}
</style>
