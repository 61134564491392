import axios from "axios";

import AuthService from '../Auth/AuthService';

export default class UsersService extends AuthService {
  /* LISTAR TODOS */
  listar() {
    return axios({
      method: 'get',
      url: this.api_url + 'usuarios?size=1000',
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }
  /* LISTAR UM */
  buscarPeloCodigo(id) {
    return axios({
      method: 'get',
      url: this.api_url + 'usuarios/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }
  /* CADASTRAR */
  criar(pessoa) {
    return axios({
      method: 'post',
      url: this.api_url + 'usuarios',
      withCredentials: true,
      data: pessoa,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  /* REMOVER */
  remover(id) {
    return axios({
      method: 'delete',
      url: this.api_url + 'usuarios/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
  /* ATUALIZAR */
  atualizar(id, pessoa) {
    return axios({
      method: 'put',
      url: this.api_url + 'usuarios/' + id,
      withCredentials: true,
      data: pessoa,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
  /* RESETAR SENHA */
  resetarSenha(id) {
    return axios({
      method: 'put',
      url: this.api_url + 'usuarios/' + id + "/resetar-password",
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  /* ALTERAR SENHA */
  alterarSenha(id, dados) {
    return axios({
      method: 'put',
      url: this.api_url + 'usuarios/' + id + '/alterar-password',
      withCredentials: true,
      data: dados,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
  /*ATIVAR E DESATIVAR USUARIO */
  alterarStatus(id, dados) {
    return axios({
      method: 'put',
      url: this.api_url + 'usuarios/' + id + '/ativo',
      withCredentials: true,
      data: dados,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
        'Content-Type': 'application/json'
      }
    });
  }
}
